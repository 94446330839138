import React, { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes as reactRouterUseRoutes, useMatch } from 'react-router-dom';
import { AuthLoader, useIsAuthenticated, useUser } from 'libs/auth';
import { AppLayout } from 'components';
import { Loader } from '@kemtai/ui';
import { ClinicProvider } from 'features/clinic';
import { wrapUseRoutes } from '@sentry/react';
import { WebSocketProvider } from 'libs/webSocket';
import { IS_EMBEDDABLE, IS_LEGACY_EMBEDDABLE } from 'config';

const useRoutes = wrapUseRoutes(reactRouterUseRoutes);

const Home = lazy(() => import('features/home'));
const Auth = lazy(() => import('features/auth'));
const Patients = lazy(() => import('features/patients'));
const Exercises = lazy(() => import('features/exercises'));
const Staff = lazy(() => import('features/staff'));
const Inbox = lazy(() => import('features/inbox'));
const Protocols = lazy(() => import('features/protocols'));
const Assessments = lazy(() => import('features/assessments'));
const Education = lazy(() => import('features/education'));
const Metrics = lazy(() => import('features/metrics'));
const Medications = lazy(() => import('features/medications'));
const Clinic = lazy(() => import('features/clinic'));

const Embed = lazy(() => import('features/embed'));
const Embeddables = lazy(() => import('features/embeddables'));


export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="auth/login" />
  },
  {
    path: "/auth/*",
    element: <Auth />,
  },
  {
    path: "/embed/*",
    element: <>Loading</>
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
];


export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/exercises/*",
        element: <Exercises />,
      },
      {
        path: "/patients/*",
        element: <Patients />,
      },
      {
        path: "/staff-members/*",
        element: <Staff />,
      },
      {
        path: "/inbox/*",
        element: <Inbox />,
      },
      {
        path: "/library/*",
        element: null,
      },
      {
        path: "/protocols/*",
        element: <Protocols />,
      },
      {
        path: "/assessments/*",
        element: <Assessments />,
      },
      {
        path: "/education/*",
        element: <Education />,
      },
      {
        path: "/metrics/*",
        element: <Metrics />,
      },
      {
        path: "/medications/*",
        element: <Medications />,
      },
      {
        path: "/clinic/*",
        element: <Clinic />,
      }
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
];


export const embedRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Embeddables />
  },
];

const Router = () => {
  const protectedElement = useRoutes(protectedRoutes) as JSX.Element;
	const publicElement = useRoutes(publicRoutes) as JSX.Element;
  const embedElement = useRoutes(embedRoutes) as JSX.Element;

  if (IS_EMBEDDABLE) {
    return (
      <>
        {embedElement}
      </>
    );
  } else if (IS_LEGACY_EMBEDDABLE) {
    return (
      <>
        {embedElement}
      </>
    );
  } else {
    return (
      <AuthLoader
        renderLoading={() => <Loader fullscreen title="Authenticating" />}
        renderUnauthenticated={() => publicElement}
      >
        <ClinicProvider>
          <WebSocketProvider>
            {protectedElement}
          </WebSocketProvider>
        </ClinicProvider>
      </AuthLoader>
    );
  }
  
};

export default Router;
