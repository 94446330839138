import { zodResolver } from '@hookform/resolvers/zod';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, UseFormReturn, SubmitHandler, UseFormProps, FormProvider, FieldValues } from 'react-hook-form';
import { ZodType, ZodTypeDef, z } from 'zod';


export type FormProps<TFormValues extends FieldValues, Schema> = {
  onSubmit: SubmitHandler<TFormValues>;
  children: (form: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  id?: string;
  schema?: Schema;
  style?: React.HTMLProps<HTMLFormElement>['style'];
};

export const Form = <
  Schema extends ZodType<any, any, any>,
  TFormValues extends FieldValues = z.infer<Schema>,
>({
  onSubmit,
  children,
  options,
  id,
  schema,
  style,
}: FormProps<TFormValues, Schema>) => {
  const form = useForm<TFormValues>({ resolver: schema && zodResolver(schema), ...options });
  const { isValid} = form.formState;

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset(undefined, { keepValues: true });
    }
  }, [form.formState, form.reset]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        id={id}
        style={style}
      >
        {children(form)}
      </form>
    </FormProvider>
  );
};