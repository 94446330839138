import { useAuthorization } from "libs/auth";
import { SidebarMenuItem } from "./types";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { UnreadMessagesBadge } from "features/chat";
import SettingsIcon from '@mui/icons-material/Settings';
import { Children } from "react";
import { MetricsIcon } from "assets/icons";
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import MedicationIcon from '@mui/icons-material/Medication';
import SchoolIcon from '@mui/icons-material/School';
import AddchartIcon from '@mui/icons-material/Addchart';
import SpaIcon from '@mui/icons-material/Spa';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useClinic } from "features/clinic";


export const useMenuItems = () => {
  const { checkAccess } = useAuthorization();
  const { clinic } = useClinic();

  const menuItems: SidebarMenuItem[] = [
    { 
      label: "Home", 
      path: "/",
      icon: <DashboardIcon 
              fontSize="inherit"
            />
    },
    { 
      label: "Patients", 
      icon: <PeopleIcon 
              fontSize="inherit"
            />,
      children: [
        {
          label: "Active",
          path: "/patients"
        },
        {
          label: "Discharged",
          path: "/patients/discharged"
        }
      ]
    },
    {
      label: "Inbox",
      path: "/inbox",
      icon: (
        <UnreadMessagesBadge>
          <QuestionAnswerIcon 
            fontSize="inherit"
          />
        </UnreadMessagesBadge>
      ),
    },
    {
      label: "Library",
      icon: <VideoLibraryIcon 
              fontSize="inherit"
            />,
      inline: true,
      children: [
        {
          label: "Exercises",
          icon: <SportsGymnasticsIcon 
                  fontSize="inherit"
                />,
          children: [
            {
              label: "Rehab",
              path: "/exercises/rehab"
            },
            {
              label: "Fitness",
              path: "/exercises/fitness"
            }
          ]
        },
        {
          label: "Metrics",
          path: "/metrics",
          icon: <MetricsIcon 
                  fontSize="inherit"
                />,
          isPermitted: !!clinic?.permissions.features?.metrics
        },
        {
          label: "Medications",
          path: "/medications",
          icon: <MedicationIcon 
                  fontSize="inherit"
                />,
          isPermitted: !!clinic?.permissions.features?.medication
        },
        {
          label: "Protocols",
          icon: <SpaIcon 
                  fontSize="inherit"
                />,
          children: [
            {
              label: "Movement",
              path: "/protocols/movement",
              isPermitted: !!clinic?.permissions.features?.movement
            },
            {
              label: "Medication",
              path: "/protocols/medication",
              isPermitted: !!clinic?.permissions.features?.medication
            }
          ]
        },
        {
          label: "Assessments",
          icon: <AddchartIcon 
                  fontSize="inherit"
                />,
          children: [
            {
              label: "Range of Motion",
              path: "/assessments/rom",
              isPermitted: !!clinic?.permissions.features?.range_of_motion
            },
            {
              label: "Forms",
              path: "/assessments/forms",
              isPermitted: !!clinic?.permissions.features?.forms
            }
          ]
        },
        {
          label: "Education",
          icon: <MenuBookIcon 
                  fontSize="inherit"
                />,
          children: [
            {
              label: "Articles",
              path: "/education/articles",
              isPermitted: !!clinic?.permissions.features?.articles
            }
          ]
        },
      ]
    },
    { 
      label: "Clinic", 
      icon: <SettingsIcon 
              fontSize="inherit"
            />,
      inline: true,
      isPermitted: checkAccess({ resource: "staff", action: "list" }),
      children: [
        {
          label: "Preferences",
          path: "/clinic/preferences"
        },
        {
          label: "Staff members",
          path: "/staff-members"
        },
      ]
    },
  ];

  return menuItems;
}