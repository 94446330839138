import { Badge } from "@mui/material";
import { useUnreadChatMessages } from "features/chat";
import { IPatient } from "features/patients";
import React from "react";

type UnreadMessagesBadgeProps = {
  patient?: IPatient;
};

export const UnreadMessagesBadge: React.FC<UnreadMessagesBadgeProps> = ({ patient, children }) => {
  const { data: unreadChatMessages } = useUnreadChatMessages();

  const numOfUnreadMessages = unreadChatMessages?.filter(chatMessage => chatMessage.seen === false && (patient ? chatMessage.sender_patient === patient.id : true)).length ?? 0;

  return (
    <Badge 
      badgeContent={numOfUnreadMessages} 
      color="error"
    >
      {children}
    </Badge>
  );

}