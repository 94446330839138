import React, { useEffect } from 'react';
import { useDisclosure } from 'hooks';
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Typography, DialogProps } from '@mui/material';
import { SaveButton } from './SaveButton';
import { FieldValues, SubmitHandler, UseFormProps, UseFormReturn } from 'react-hook-form';
import { ZodType, ZodTypeDef, z } from 'zod';
import { Form, FormProps } from './Form';


type FormDialogProps<TFormValues extends FieldValues, Schema> = {
  isDone: boolean;
  deleteButton?: React.ReactElement;
  triggerButton: React.ReactElement;
  actions?: React.ReactElement;
  title: string;
  maxWidth?: DialogProps['maxWidth'];
  fullScreen?: boolean;
  saveLabel?: string;
} & FormProps<TFormValues, Schema>;

export const FormDialog = <
  Schema extends ZodType<any, any, any>,
  TFormValues extends FieldValues = z.infer<Schema>,
>({
  title,
  children,
  isDone,
  triggerButton,
  maxWidth,
  deleteButton,
  fullScreen,
  actions,
  saveLabel,
  ...formProps
}: FormDialogProps<TFormValues, Schema>) => {
  const { close, open, isOpen } = useDisclosure();

  useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: open })}

      <Dialog
        open={isOpen}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {title}

          {actions}
        </DialogTitle>

        <Form
          {...formProps}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {(methods) => (
            <>
              <DialogContent
                sx={{
                  flex: 1,
                }}
              >
                {children(methods)}
              </DialogContent>

              <DialogActions>
                {deleteButton}

                <Box flex={1} />
                
                <Button
                  onClick={close}
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>

                <SaveButton
                  onSave={methods.handleSubmit(formProps.onSubmit)}
                  size="small"
                  label={saveLabel}
                />
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};