import React from 'react';
import { Controller, FieldPath, FieldValues, UseControllerProps, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, SelectProps, Select, FormControlProps, ListSubheader, FormHelperText } from '@mui/material';
import { FormInputProps } from './FormInput';


type Option = { value: any, label: string|JSX.Element };

// type FormSelectProps = {
//   name: string;
//   control: any;
//   label?: string;
//   setValue?: any;
//   disabled?: boolean;
//   defaultValue?: any;
//   options: Option[];
//   SelectProps?: SelectProps;
// } & FormControlProps;


export type FormSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & FormControlProps & {
  options: Option[];
  SelectProps?: SelectProps;
  label?: string;
};

export const FormSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, control, options, SelectProps, label, disabled, defaultValue, ...props }: FormSelectProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FormControl
          error={!!error} 
          {...props}
        >
          <InputLabel>
            {label}
          </InputLabel>

          <Select
            value={value ?? defaultValue ?? ""}
            onChange={onChange}
            disabled={disabled}
            label={label}
            {...SelectProps}
          >
            {options.map(option =>
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )}
          </Select>

          <FormHelperText>
            {error?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
