import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from '@kemtai/ui';
import { useAnchorElement } from '@kemtai/hooks';
import { IUser } from 'features/auth';
import { useClinics, useClinic } from 'features/clinic';


export const ClinicSelect = () => {

  const { data: clinics } = useClinics();

  const { clinic, setClinic } = useClinic();

  if (!clinic || clinics?.length === 1) {
    return null;
  }

  return (
    <Select
      value={clinic.id}
      onChange={(e: SelectChangeEvent<unknown>) => setClinic(e.target.value as string)}
      variant="outlined"
      sx={{
        mx: 1,
        mb: 1,
        outline: 0
      }}
    >
      {clinics?.sort((a, b) => a.name.localeCompare(b.name))?.map(clinic =>
        <MenuItem
          key={clinic.id}
          value={clinic.id}
        >
          {clinic.name}
        </MenuItem>
      )}
    </Select>
  );

}
