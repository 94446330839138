import { useFormExitDialog } from "@kemtai/ui";
import { Button, ButtonProps, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FieldValues, FormState, useFormContext } from "react-hook-form";


enum SaveButtonState {
	Default = 0,
	Loading = 1,
	Success = 2,
	Error = 3,
}

type SaveButtonProps = {
  onSave: () => void;
  label?: string;
} & ButtonProps;

export const SaveButton: React.FC<SaveButtonProps> = ({ onSave, label, ...props }) => { 

	const { formState } = useFormContext();
	
  const [saveButtonState, setSaveButtonState] = useState<SaveButtonState>(SaveButtonState.Default);

	const { isValid, isSubmitting, isSubmitSuccessful, isDirty } = formState;

	useEffect(() => {
		if (isSubmitting) {
			if (isValid) {
				setSaveButtonState(SaveButtonState.Loading);
			} else {
				setSaveButtonState(SaveButtonState.Error);

				setTimeout(() => {
					setSaveButtonState(SaveButtonState.Default);
				}, 2000);
			}
		} else if (isSubmitSuccessful) {
			setSaveButtonState(SaveButtonState.Success);

			setTimeout(() => {
				setSaveButtonState(SaveButtonState.Default);
			}, 2000);
		}
	}, [isValid, isSubmitting, isSubmitSuccessful]);

	useFormExitDialog({
		isFormDirty: (isDirty && !isSubmitting) ?? false,
		submitFunction: onSave,
	});
  
  return (
    <Button
      variant="contained"
			type="submit"
      color={saveButtonState === SaveButtonState.Error ? 'error' : 'primary'}
      onClick={isSubmitting ? () => {} : onSave}
			data-testid="save-button"
      {...props}
    >
      <Typography
        variant="h5"
				sx={{
					opacity: saveButtonState === SaveButtonState.Default ? 1 : 0,
					transitionDuration: '300ms',
				}}
			>
				{label ?? "Save"}
			</Typography>

      <CircularProgress
				size={24}
				color="inherit"
				sx={{
					position: 'absolute',
					opacity: saveButtonState === SaveButtonState.Loading ? 1 : 0,
					transitionDuration: '300ms',
				}}
			/>

			<CheckIcon
				sx={{
					position: 'absolute',
					opacity: saveButtonState === SaveButtonState.Success ? 1 : 0,
					transitionDuration: '300ms',
				}}
			/>

			<ErrorOutlineIcon
				sx={{
					position: 'absolute',
					opacity: saveButtonState === SaveButtonState.Error ? 1 : 0,
					transitionDuration: '300ms',
				}}
			/>
    </Button>
  );

}