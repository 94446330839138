import React from "react";
import { Divider, Typography, useTheme } from "@mui/material";
import { FormDialog, FormInput } from "components";
import { contactSupportSchema, useContactSupport } from "features/support";
import { z } from "zod";


type ContactSupportDialogProps = {
  triggerButton: React.ReactElement;
};

export const ContactSupportDialog: React.FC<ContactSupportDialogProps> = ({ triggerButton }) => {

  const theme = useTheme();
  
  const contactSupportMutation = useContactSupport();

  const onSubmit = async (data: z.infer<typeof contactSupportSchema>) => {
    await contactSupportMutation.mutateAsync({
      data
    });
  }

  return (
    <FormDialog
      isDone={contactSupportMutation.isSuccess}
      schema={contactSupportSchema}
      onSubmit={onSubmit}
      title="Contact support"
      triggerButton={triggerButton}
      saveLabel="Send"
      maxWidth="md"
    >
      {({ control }) => (
        <>
          <FormInput 
            label="Message"
            name="message"
            multiline
            rows={5}
            control={control}
            sx={{
              width: "500px"
            }}
          />

          <Typography
            variant="caption1"
            mt={2}
            display="block"
          >
            You can also contact us at:

            <br />

            <a href="mailto:support@kemtai.com" style={{ textDecoration: "underline" }}>support@kemtai.com</a>
            
            <div style={{ direction: 'ltr' }}>
              <a href="tel:Tel: +972 3-577-2021" style={{ textDecoration: "underline" }}>+972 3-577-2021</a> <strong>IL</strong> (24/7) 
              <br />
              <a href="tel:Tel: +972 54-934-9094" style={{ textDecoration: "underline" }}>+972 54-934-9094</a> <strong>IL</strong> (Sun-Thu 09:00 AM - 16:00 PM)
              <br />
              <a href="tel:Tel: +44 20 3995 3665" style={{ textDecoration: "underline" }}>+44 20 3995 3665</a> <strong>EU</strong> 
              <br />
              <a href="tel:Tel: +1 (929) 227-3479" style={{ textDecoration: "underline" }}>+1 (929) 227-3479</a> <strong>US</strong> 
            </div>
          </Typography>
        </>
      )}
    </FormDialog>
  );

}
