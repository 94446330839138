import React, { useContext, useEffect, useState } from "react";
import { AppearanceOptions, EmbeddablePropsMap as StandaloneEmbeddablePropsMap } from "@kemtai/web-sdk";
import { EmbeddablePropsMap as CareEmbeddablePropsMap } from "@kemtai/web-care-sdk";
import { storage } from "libs/storage";
import { decodeToken } from "utils/jwt";
import { useEmbeddableTheme } from "../theme";


type MessagePayload<
  StandaloneEmbeddable extends keyof StandaloneEmbeddablePropsMap = keyof StandaloneEmbeddablePropsMap,
  CareEmbeddable extends keyof CareEmbeddablePropsMap = keyof CareEmbeddablePropsMap,
> = {
  type: "token";
  token: string;
} | {
  type: "appearance";
  appearance?: AppearanceOptions;
} | {
  type: "props";
  embeddableName: StandaloneEmbeddable;
  props: {
    runType: "standalone",
  } & StandaloneEmbeddablePropsMap[StandaloneEmbeddable];
} | {
  type: "props";
  embeddableName: CareEmbeddable;
  props: {
    runType: "care",
  } & CareEmbeddablePropsMap[CareEmbeddable];
};


type EmbeddablePropsMap<T> = T extends "standalone"
  ? StandaloneEmbeddablePropsMap
  : CareEmbeddablePropsMap;

type EmbeddableContextValue = {
  getProps: <
    T extends "standalone" | "care", 
    K extends keyof EmbeddablePropsMap<T>
  >(runType: T, name: K) => EmbeddablePropsMap<T>[K] | undefined;
  isAuthenticated: boolean;
};

export const EmbeddableContext = React.createContext<EmbeddableContextValue>({} as EmbeddableContextValue);

export const EmbeddableProvider: React.FC = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [standaloneEmbeddables, setStandaloneEmbeddables] = useState<Partial<StandaloneEmbeddablePropsMap>>({});
  const [careEmbeddables, setCareEmbeddables] = useState<Partial<CareEmbeddablePropsMap>>({});

  const { setAppearance } = useEmbeddableTheme();

  const handleMessage = (message: MessageEvent<MessagePayload>) => {
    if (message.data.type === "token") {
      storage.setAccessToken(message.data.token);

      const decodedToken: any = decodeToken(message.data.token);
      storage.setItem("clinicId", decodedToken.owner_id);
      setIsAuthenticated(true);
    } else if (message.data.type === "appearance") {
      setAppearance(message.data.appearance);
    } else if (message.data.type === "props") {
      if (message.data.props.runType === "standalone") {
        setStandaloneEmbeddables({
          ...standaloneEmbeddables,
          [message.data.embeddableName]: message.data.props,
        });
      } else {
        setCareEmbeddables({
          ...careEmbeddables,
          [message.data.embeddableName]: message.data.props,
        });
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    window.parent.postMessage({
      type: "ready"
    }, "*");

    return () => window.removeEventListener('message', handleMessage);;
  }, []);

  const getProps = <T extends "standalone" | "care", K extends keyof EmbeddablePropsMap<T>>(runType: T, name: K) => {
    if (runType === "standalone") {
      return standaloneEmbeddables[name as keyof StandaloneEmbeddablePropsMap] as EmbeddablePropsMap<T>[K];
    } else {
      return careEmbeddables[name as keyof CareEmbeddablePropsMap] as EmbeddablePropsMap<T>[K];
    }
  }

  return (
    <EmbeddableContext.Provider 
      value={{ 
        getProps,
        isAuthenticated,
      }}
    >
      {children}
    </EmbeddableContext.Provider>
  );

}

export const useEmbeddable = () => {
  return useContext(EmbeddableContext);
}

export const refreshEmbeddableToken = () => {
  return new Promise<{ access: string }>((resolve, reject) => {
    window.parent.postMessage({
      type: "token_expired"
    }, "*");

    const handleMessage = (message: MessageEvent) => {
      if (message.data.type === "token") {
        resolve({
          access: message.data.token
        });

        window.removeEventListener("message", handleMessage);
      }
    }

    window.addEventListener("message", handleMessage);
  });
}