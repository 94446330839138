import { IntlProvider } from "react-intl";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProviderProps } from "@mui/lab";
import { useEffect, useState } from "react";
import dayjs from "dayjs";


export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_FORMAT = () => dayjs.localeData().longDateFormat('ll').replace(/,?\s*YYYY/g, "");

export enum Locale {
  EN_US = "en-US",
  HE = "he",
  ES_US = "es-US",
  IT = "it",
  DE = "de",
  ES = "es",
  PL = "pl",
};

export const LANGUAGE_OPTIONS = [
  { 
    value: Locale.EN_US, 
    label: <>English (US)</>
  },
  { 
    value: Locale.HE, 
    label: <>Hebrew</>
  },
  { 
    value: Locale.ES, 
    label: <>Spanish</>
  },
  {
    value: Locale.ES_US, 
    label: <>Spanish (US)</>
  },
  { 
    value: Locale.IT, 
    label: <>Italian</>
  },
  { 
    value: Locale.DE, 
    label: <>German</>
  },
  { 
    value: Locale.PL, 
    label: <>Polish</>
  },
];

export const isRTL = (locale: Locale) => {
  return [
    Locale.HE
  ].includes(locale);
}

export const defaultLocale = Locale.EN_US;

const DAYJS_LOCALES = {
  [Locale.EN_US]: import("dayjs/locale/en"),
  // [Locale.HE]: import("dayjs/locale/he"),
};

const DATE_PICKER_LOCALES_IMPORTS = {
  [Locale.EN_US]: async () => { const { enUS } = await import("@mui/x-date-pickers/locales/enUS"); return enUS; },
  // [Locale.HE]: async () => { const { heIL } = await import("@mui/x-date-pickers/locales/heIL"); return heIL; },
};

export const LocaleProvider: React.FC = ({ children }) => {

  const locale = defaultLocale;
  const [datePickerLocaleText, setDatePickerLocaleText] = useState<LocalizationProviderProps['localeText']|undefined>(undefined);

  useEffect(() => {
    async function changeLocale() {
      const localteData = await DAYJS_LOCALES[locale];
      dayjs.locale(locale);

      const _datePicketLocaleText = await DATE_PICKER_LOCALES_IMPORTS[locale]();
      setDatePickerLocaleText(_datePicketLocaleText.components.MuiLocalizationProvider.defaultProps.localeText);
    }

    changeLocale();
  }, [locale]);

  return (
    <IntlProvider 
      messages={{}} 
      locale={locale}
      defaultLocale={defaultLocale}
    >
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={locale}
        localeText={datePickerLocaleText}
      >
       {children}
      </LocalizationProvider>
    </IntlProvider>
  );

}