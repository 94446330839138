import Axios, { InternalAxiosRequestConfig } from 'axios';
import { API_URL, IS_EMBEDDABLE } from 'config';
import { storage } from 'libs/storage';
import { refreshToken } from 'features/auth/api';
import { useNotificationStore } from 'stores/notifications';
import { applyAuthInterceptor } from "@kemtai/api-client";
import { refreshEmbeddableToken } from 'features/embeddables/libs/embeddable';


export const axios = Axios.create({
  baseURL: API_URL,
  paramsSerializer: {
    indexes: null
  }
});


async function clinicRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
  const clinicId = storage.getItem("clinicId");

  if (clinicId && (config.url?.startsWith("care/") || config.url?.startsWith("/care/"))) {
    config.headers!['X-ClinicId'] = clinicId;
  }

  return config;
}

async function localizationRequestInterceptor(config: InternalAxiosRequestConfig<any>) {

  if (/^\/?auth/.test(config?.url ?? "")) {
    config.headers!['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  config.headers!['Accept-Language'] = "en";
  
  return config;
}

axios.interceptors.request.use(localizationRequestInterceptor);
axios.interceptors.request.use(clinicRequestInterceptor);

if (IS_EMBEDDABLE) {
  applyAuthInterceptor(
    axios,
    {
      requestRefresh: refreshEmbeddableToken,
      getStorage: () => storage,
      useRefreshToken: false
    }
  );
} else {
  applyAuthInterceptor(
    axios,
    {
      requestRefresh: refreshToken,
      getStorage: () => storage,
    }
  );
}

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status >= 500) {
      useNotificationStore.getState().addNotification({
        type: "error",
        title: "Error",
        message: "Something went wrong. Please try again.",
      });
    }

    return Promise.reject(error);
  }
);
